import React, { Children, Suspense, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import styled from "styled-components";
import "tippy.js/dist/tippy.css";
import { Header } from "./layout";
import { useLoading, useToken } from "./store/store";
import "react-multi-carousel/lib/styles.css";
import useMediaQuery from "./layout/hooks/useMediaQuery";
import Loader from "./common/loader/Loader";
import Login from "./Login";
import Aside from "./common/menu/Aside";
import { Product } from "./assets/icons/Product";
import Error from "./Error/Error";
import ProductInformation from "@bjsdistribution/dms-product-information";

import Debrief from "@bjsdistribution/dms-finance";
import RouteModule from "@bjsdistribution/dms-routes";

import Order from "@bjsdistribution/dms-orders";
import Flags from "@bjsdistribution/dms-flags";
import InvoiceModule from "@bjsdistribution/dms-invoice";
import OffShoreModule from "@bjsdistribution/dms-offshore";
import Reminder from "./layout/Reminder";
import ReturnClient from "@bjsdistribution/dms-client-return";
const AppRouter: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [token] = useToken();
  const [loading] = useLoading();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  // if (loading) {
  //   return <Loader title="Logging in" />;
  // }

  // if (!token) {
  //   return null;
  // }
  const LoadingMessage = () => <div>Loading...</div>;
  return (
    <>
      <Router>
        <Login />
        <Reminder />
        <MainContainer>
          <div className="dashboard">
            <Header />
            <div
              className={
                isExpanded
                  ? "placeHolderWrapper expanded"
                  : "placeHolderWrapper collapsed"
              }
            >
              <Aside toggleSidebar={toggleSidebar} />
              <div className="placeHolder">
                <Suspense fallback={<LoadingMessage />}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/route" />} />
                    <Route
                      path="/product/*"
                      element={<ProductInformation token={token} />}
                    />
                    <Route
                      path="/finance/*"
                      element={<Debrief token={token!} />}
                    />
                    <Route path="/route/*" element={<RouteModule />} />

                    <Route path="/order/*" element={<Order />} />
                    <Route path="/flag/*" element={<Flags token={token!} />} />
                    <Route
                      path="finance/invoice/*"
                      element={<InvoiceModule />}
                    />
                    <Route path="/offshore/*" element={<OffShoreModule />} />
                    <Route path="*" element={<Error />} />
                    <Route path="/returnclient/*" element={<ReturnClient />} />
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
        </MainContainer>
      </Router>
    </>
  );
};

const RightContainer = styled.div`
  background: #f9f9f9;
  padding: 85px 20px 30px 20px;
  flex: 1;
`;

const MainContainer = styled.div`
  /* display: flex; */

  .dashboard {
    flex: 1;
    transition: all ease 0.5s;
    .placeHolder {
      padding: 72px 20px 10px 20px;
      flex: 1 1 0%;
    }
  }

  .placeHolderWrapper.collapsed {
    width: 100vw;
  }
  .placeHolderWrapper {
    /* display: flex; */
    width: 100%;
    padding-left: 260px;
    transition: all ease 0.5s;
    &.expanded {
      padding-left: 61px;

      .submenu {
        transform: translateX(-130%);
      }
      .toggle-icon {
        right: -71px !important;
      }
    }
  }
`;

export default AppRouter;
