import React from "react";
import styled from "styled-components";

interface Props {
  className?: any;
}

const StyledIconBoxes = styled.svg`
  & .path {
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }
`;
const ClientReturn = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconBoxes
      className={`icon-boxes ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 20 16"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7C4.10313 7 5 6.10313 5 5C5 3.89687 4.10313 3 3 3C1.89688 3 1 3.89687 1 5C1 6.10313 1.89688 7 3 7ZM17 7C18.1031 7 19 6.10313 19 5C19 3.89687 18.1031 3 17 3C15.8969 3 15 3.89687 15 5C15 6.10313 15.8969 7 17 7ZM18 8H16C15.45 8 14.9531 8.22188 14.5906 8.58125C15.85 9.27188 16.7437 10.5188 16.9375 12H19C19.5531 12 20 11.5531 20 11V10C20 8.89687 19.1031 8 18 8ZM10 8C11.9344 8 13.5 6.43437 13.5 4.5C13.5 2.56562 11.9344 1 10 1C8.06563 1 6.5 2.56562 6.5 4.5C6.5 6.43437 8.06563 8 10 8ZM12.4 9H12.1406C11.4906 9.3125 10.7688 9.5 10 9.5C9.23125 9.5 8.5125 9.3125 7.85938 9H7.6C5.6125 9 4 10.6125 4 12.6V13.5C4 14.3281 4.67188 15 5.5 15H14.5C15.3281 15 16 14.3281 16 13.5V12.6C16 10.6125 14.3875 9 12.4 9ZM5.40938 8.58125C5.04688 8.22188 4.55 8 4 8H2C0.896875 8 0 8.89687 0 10V11C0 11.5531 0.446875 12 1 12H3.05938C3.25625 10.5188 4.15 9.27188 5.40938 8.58125Z"
        fill="rgb(174, 169, 161)"
      />
    </StyledIconBoxes>
  );
};

export { ClientReturn };
